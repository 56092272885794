/** @jsx jsx */
import { Link } from "gatsby"
import { jsx } from "theme-ui"
import { Flex } from "@theme-ui/components"
import replaceSlashes from "../utils/replaceSlashes"
import useSiteMetadata from "../hooks/use-site-metadata"
import useMinimalBlogConfig from "../hooks/use-minimal-blog-config"

const HeaderTitle = () => {
  const { siteTitle, siteImage } = useSiteMetadata()
  const { basePath } = useMinimalBlogConfig()

  return (
    <Link
      to={replaceSlashes(`/${basePath}`)}
      aria-label={`${siteTitle} - Back to home`}
      sx={{ color: `display`, textDecoration: `none` }}
    >
      <Flex sx={{flexDirection: `row`, alignItems: `center`}}>
        <img src={`${siteImage}`} width="90" sx={{borderRadius: `50%`, marginRight: '20px'}}></img>
        <span sx={{ my: 0, fontWeight: `bold`, fontSize: [4 , 5] }}>{siteTitle}</span>
      </Flex>
    </Link>
  )
}

export default HeaderTitle