/** @jsx jsx */
import { jsx, Link } from "theme-ui"
import useSiteMetadata from "../hooks/use-site-metadata"

const Footer = () => {
  const { siteTitle } = useSiteMetadata()

  return (
    <footer
      sx={{
        boxSizing: `border-box`,
        display: `flex`,
        justifyContent: `space-between`,
        mt: [6],
        color: `secondary`,
        a: {
          variant: `links.secondary`,
        },
        flexDirection: [`column`, `column`, `row`],
        variant: `dividers.top`,
        p: {
          marginBottom: '2px',
          marginTop: '2px'
        }
      }}
    >
      <div>
        &copy; 2005 - {new Date().getFullYear()} by {siteTitle}. All rights reserved.
      </div>
      <div>
        <p><a href="mailto:simone.romanow@gmail.com?subject=Message to Simone Romanow">simone.romanow[at]gmail.com</a></p>
        <p>Tax ID: NL003950187B54</p> 
        <p>Chamber of Commerce: 84301643</p>
      </div>
    </footer>
  )
}

export default Footer
